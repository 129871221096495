import { IDLClassWithSerialize } from '/common/models';
import { IEntityPromotion } from '/api';

import { IHandlePromotionsChangeParams } from './types';

export const handlePromotionsChange = <ID extends IDLClassWithSerialize>({
  promotionChanged,
  promotions,
  updateCachedPromotions,
}: IHandlePromotionsChangeParams<ID>) => {
  const nextPromotions: IEntityPromotion<ID>[] = [...promotions];
  const promotionChangedIDSerialized: unknown = promotionChanged.id.serialize();
  const promotionIndex: number = promotions.findIndex((p) => p.id.serialize() === promotionChangedIDSerialized);

  if (promotionIndex === -1) {
    nextPromotions.push(promotionChanged);
  } else {
    nextPromotions[promotionIndex] = promotionChanged;
  }

  updateCachedPromotions(nextPromotions);
};
