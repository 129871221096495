import { DevicesSortField, DevicesSort, Device, DeviceSerialized } from '@playq/octopus2-devices';

import { IQuery } from '/common/models';

export enum IDeviceIcon {
  Unknown = 'UNKNOWN',
  Known = 'KNOWN',
  Cancel = 'CANCEL',
  Check = 'CHECK',
  Remove = 'REMOVE',
  Save = 'SAVE',
  Edit = 'EDIT',
  Subscribe = 'SUBSCRIBE',
  Unsubscribe = 'UNSUBSCRIBE',
}

export type DeviceQuery = IQuery<DevicesSortField, DevicesSort>;

export interface DeviceTableProps {
  subscribeDevice: (deviceID: string, device: Device) => void;
  changeSubscription: (deviceID: string, device: Device) => void;
  unsubscribe: (value: boolean) => void;
}
export type StorageDevice = { deviceID: string; device: DeviceSerialized };
