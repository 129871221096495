import { FC, memo, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { PhoneIphone as MobileIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { DeviceID, UnknownDevice, KnownDevice, Device } from '@playq/octopus2-devices';

import { onlineDevicesManagement, services2, unwrapEither } from '/api';
import { useModal } from '/hooks';
import { deviceToolkit, globalDispatch } from '/store';
import { buzzerOnlineDevicesNotifications$ } from '/common/buzzer/buzzerNotifications';
import { SelectDialog } from '/shared/SelectDialog';
import { createStorage, localStorageMethods } from '/storage';
import { snackbarService } from '/common/snackbarService';
import { BuzzerOnlineDevicesNotifications } from '/common/buzzer';

import { BadgeStyled, ButtonStyled } from './styles';
import { checkNameInDevice } from './helpers';
import { StorageDevice } from './types';
import { ButtonGroup } from './components';
import { DeviceTable } from './DeviceTable';
import { LoadingTextProgress } from './ProgressText';

export const OnlineDevices: FC = memo(() => {
  const storage = createStorage({ storage: localStorageMethods });
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  //TODO: remove subscriptions
  const subscribtions = useSelector(deviceToolkit.selectors.devices);
  const subscriptionDevice = useSelector(deviceToolkit.selectors.subscribtionDevice);
  const [loadingConnection, setLoadingConnection] = useState(false);
  const { open, close, isOpen } = useModal();
  const { updateConnectWithDevice, setStreamEvent, subscribeDevice, unsubscribeDevice } = globalDispatch(dispatch);
  const localStorageDevice = storage.get('DeviceID');
  const location = useLocation();
  const hasNotSubscribtions = subscribtions.length === 0;

  const handleDeviceNotification = (notification: BuzzerOnlineDevicesNotifications) => {
    if (notification instanceof KnownDevice || notification instanceof UnknownDevice) {
      updateConnectWithDevice(notification);
      if (notification.connection !== undefined) {
        if (location.pathname.includes('device')) {
          snackbarService.success('Device online');
        }
      }
    } else {
      setStreamEvent(notification);
    }
  };
  const refreshDevice = () => {
    return queryClient.invalidateQueries(onlineDevicesManagement);
  };
  const subscribe = (
    deviceID: string,
    device: Device,
    reloadPage = false,
    changeConnectionStatus?: (val: boolean) => void
  ) => {
    unwrapEither(services2.onlineDevicesEphemeral.sendSubscription(new DeviceID(deviceID)))
      .then((res) => {
        const status = { ...device.serialize(), connection: res.connection?.serialize() };
        storage.set('DeviceID', JSON.stringify({ deviceID: deviceID, device: status }));
        subscribeDevice(checkNameInDevice(status));
        if (changeConnectionStatus) {
          changeConnectionStatus(false);
        }
        if (!reloadPage) {
          close();
        }
      })
      .catch((err) => {
        console.error(err);
        if (changeConnectionStatus) {
          changeConnectionStatus(false);
        }
      });
  };

  const changeSubscription = (currentId: string, pickedDevice: Device) => {
    if (currentId !== pickedDevice.id.serialize()) {
      unwrapEither(services2.onlineDevicesEphemeral.sendUnsubscription(new DeviceID(currentId))).then(() => {
        subscribe(pickedDevice.id.serialize(), pickedDevice, true);
      });
    }
  };
  const unsubscribe = (reloadPage = true) => {
    if (hasNotSubscribtions) return;

    unwrapEither(services2.onlineDevicesEphemeral.sendUnsubscription(new DeviceID(subscribtions[0].id))).then(() => {
      storage.remove('DeviceID');
      unsubscribeDevice(subscribtions[0].id);
      if (reloadPage) {
        close();
      }
    });
  };
  useEffect(() => {
    const deviceNotifications = buzzerOnlineDevicesNotifications$.subscribe(handleDeviceNotification);
    if (localStorageDevice) {
      const storageDevice = JSON.parse(localStorageDevice) as StorageDevice;
      if (storageDevice !== null && !subscriptionDevice?.device) {
        setLoadingConnection(true);
        setTimeout(() => {
          subscribe(storageDevice.deviceID, checkNameInDevice(storageDevice.device), true, setLoadingConnection);
        }, 2000);
      }
    }
    return () => {
      deviceNotifications.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDeviceDialog = () => {
    open();
  };
  return (
    <>
      <Tooltip title='View devices' placement='bottom'>
        <ButtonStyled onClick={openDeviceDialog} color='inherit'>
          <BadgeStyled
            $offline={subscriptionDevice?.device.connection === undefined}
            invisible={subscriptionDevice?.device === undefined}
            variant='dot'
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'bottom',
            }}
          >
            <MobileIcon />
          </BadgeStyled>
          {loadingConnection ? <LoadingTextProgress /> : subscriptionDevice?.name}
        </ButtonStyled>
      </Tooltip>
      {isOpen && (
        <SelectDialog
          title='Devices'
          open={isOpen}
          onClose={close}
          actions={
            <ButtonGroup
              tooltipText={`${hasNotSubscribtions ? 'You are not subscribed' : ''}`}
              refetch={refreshDevice}
              unsubscribe={unsubscribe}
              disabled={hasNotSubscribtions}
            />
          }
          content={
            <DeviceTable
              subscribeDevice={subscribe}
              unsubscribe={unsubscribe}
              changeSubscription={changeSubscription}
            />
          }
        />
      )}
    </>
  );
});
