import { FC, ReactNode } from 'react';
import { ArrowForward } from '@mui/icons-material';

import { ArrowWrapper, Wrapper, Content } from './styles';

export interface IPromoteButtonProps {
  label?: ReactNode;
  version?: number;
  disabled?: boolean;
  withArrow?: boolean;
  onClick?: () => void;
  className?: string;
}

export const Button: FC<IPromoteButtonProps> = ({ label, version, onClick, withArrow = true, ...props }) => {
  const handleClick = () => {
    if (!props.disabled && onClick) {
      onClick();
    }
  };

  return (
    <Wrapper onClick={handleClick} tabIndex={0} {...props}>
      <Content>
        {label}
        {version !== undefined && <div>{version}</div>}
      </Content>
      {withArrow && (
        <ArrowWrapper>
          <ArrowForward fontSize='small' />
        </ArrowWrapper>
      )}
    </Wrapper>
  );
};
