import { FC, ReactNode } from 'react';
import cs from 'classnames';

import { Wrapper, Title, Text } from './styles';

export interface IPromoteStatusProp {
  title: ReactNode;
  text: ReactNode;
  loading?: boolean;
  className?: string;
}

export const Status: FC<IPromoteStatusProp> = (props) => {
  const { title, text, loading = false, className } = props;
  return (
    <Wrapper className={cs(className, { loading })}>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Wrapper>
  );
};
