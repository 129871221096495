import { Menu, css, styled } from '@mui/material';

import { IMenuCategorizedProps } from './types';

export const MenuCategorized = styled(Menu)<IMenuCategorizedProps>`
  ${({ theme, $label }) => css`
    .MuiList-root {
      position: relative;
      padding: 2rem 0 0;
      min-width: 140px;
      min-height: 65px;

      &:before {
        content: ${$label ? `'${$label}:'` : 'none'};
        top: 0.6rem;
        color: ${theme.palette.text.primary};
        font-size: 0.9rem;
        font-weight: 500;
        position: absolute;
        left: 10px;
      }
    }
  `}
`;
