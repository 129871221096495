import { FC, forwardRef, ReactElement, ReactNode } from 'react';
import { AppBar, Dialog, DialogContent, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';

import { mergeClasses } from '/helpers';

import { usePreviewEntityStyles } from './styles';

interface IPreviewEntityClasses extends Record<string, string | undefined> {
  header?: string;
  toolBar?: string;
  wrapper?: string;
  closeWrapper?: string;
  content?: string;
}

interface IPreviewEntityProps {
  content: ReactNode;
  handleClose: () => void;
  open: boolean;
  title: string;
  classes?: IPreviewEntityClasses;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Transition = forwardRef<unknown, TransitionProps & { children: ReactElement<any, any> }>((props, ref) => (
  <Slide direction='up' ref={ref} {...props}>
    {props.children}
  </Slide>
));

export const PreviewEntity: FC<IPreviewEntityProps> = (props) => {
  const { content, handleClose, open, title, classes: propsClasses = {} } = props;

  const internalClasses = usePreviewEntityStyles();
  const classes = mergeClasses<IPreviewEntityClasses>(internalClasses, propsClasses);

  return (
    <Dialog fullScreen={true} open={open} TransitionComponent={Transition}>
      <AppBar className={classes.header}>
        <Toolbar className={classes.toolBar}>
          <div className={classes.wrapper}>
            <Typography variant='h6'>{title}</Typography>
            <div className={classes.closeWrapper}>
              <Typography variant='h6'>Close</Typography>
              <IconButton
                edge='end'
                data-testid='close-preview-icon'
                color='inherit'
                onClick={handleClose}
                size='large'
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>{content}</DialogContent>
    </Dialog>
  );
};
