export const getEmojiFromCountryCode = (countryCode: string): string => {
  if (!countryCode) {
    return '';
  }

  const firstRegularChar = 'A';
  const firstRegularCharUnicodeCountingStart: number = firstRegularChar.charCodeAt(0);
  const FIRST_REGION_CHAR_UNICODE_COUNTING_START = 127462;

  return String.fromCodePoint(
    ...countryCode
      .toUpperCase()
      .split('')
      .map(
        (char) => FIRST_REGION_CHAR_UNICODE_COUNTING_START + char.charCodeAt(0) - firstRegularCharUnicodeCountingStart
      )
  );
};
