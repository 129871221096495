import { useState, useEffect } from 'react';

import { Notification, SpaceCreated, SpaceUpdated, SpaceDeleted, UserLogin } from '@playq/octopus-notifications';

import { SpaceNotificationView } from './SpaceNotificationView';
import UserLoginNotificationView from './UserLoginNotificationView';
import { CodeWrapper } from './styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const specializedRenderers: { [key: string]: any } = {
  [SpaceCreated.FullClassName]: SpaceNotificationView,
  [SpaceUpdated.FullClassName]: SpaceNotificationView,
  [SpaceDeleted.FullClassName]: SpaceNotificationView,
  [UserLogin.FullClassName]: UserLoginNotificationView,
};

export interface INotificationViewProps {
  notification: Notification;
  plain?: boolean;
  inline?: boolean;
}

export function NotificationView(props: INotificationViewProps) {
  const { notification, plain, inline } = props;

  const [json, setJson] = useState(JSON.stringify(notification.serialize(), undefined, '  '));
  const [fsn, setFSN] = useState(notification.getFullClassName());

  useEffect(() => {
    setJson(JSON.stringify(notification.serialize(), undefined, '  '));
    setFSN(notification.getFullClassName());
  }, [notification, plain]);

  if (!plain) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const Specialized = specializedRenderers[fsn];
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (Specialized) {
      // Since each specialized component will have a cast, we do any cast here so we
      // don't have to recast inside
      // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-assignment
      return <Specialized notification={notification as any} inline={inline} />;
    }
  }

  if (inline) {
    return <span>{notification.message}</span>;
  }

  return <CodeWrapper>{json}</CodeWrapper>;
}

export default NotificationView;
