import { IconButton, TextField } from '@mui/material';
import React, { FC, memo } from 'react';
import { Check as CheckIcon, Close as CancelIcon } from '@mui/icons-material';

interface FieldNameProps {
  value: string;
  onSave: () => void;
  onCancel: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}
export const FieldName: FC<FieldNameProps> = memo(({ onSave, onCancel, onChange, value }) => {
  const saveFieldWithKeyboard = (e: React.KeyboardEvent<HTMLDivElement>) => {
    return e.key === 'Enter' ? onSave() : '';
  };
  return (
    <>
      <TextField
        data-testid='input-edit-name'
        variant='standard'
        onChange={onChange}
        value={value}
        error={!value}
        helperText={!value ? 'Name can not be empty' : undefined}
        onKeyUp={saveFieldWithKeyboard}
      />
      <IconButton onClick={onSave}>
        <CheckIcon color='success' />
      </IconButton>
      <IconButton onClick={onCancel}>
        <CancelIcon color='error' />
      </IconButton>
    </>
  );
});
