import { EntityPromotionsResponse, PromotionResponse } from '@playq/octopus-common';
import { Space } from '@playq/octopus2-apps';

export const getSandboxPromotion = (
  promotions?: EntityPromotionsResponse,
  space?: Space
): PromotionResponse | undefined => {
  if (!promotions) {
    return;
  }
  if (space) {
    const firstSpace = promotions.sandbox.custom.find(
      (spacePromotion) => spacePromotion.id.serialize() === space.id.serialize()
    );
    if (firstSpace) {
      return firstSpace.promotion;
    }

    return;
  }

  if (promotions.sandbox.default_) {
    return promotions.sandbox.default_;
  }
};
