import { AccordionProps, PopoverActions } from '@mui/material';
import { FC, useMemo, useRef } from 'react';

import { MenuCategorizedContent } from '/shared/MenuCategorizedContent';

import { MenuCategorized } from './styles';
import { IMenuCategorizedPopupProps } from './types';

export const MenuCategorizedPopup: FC<IMenuCategorizedPopupProps> = (props: IMenuCategorizedPopupProps) => {
  const { isOpen, anchorEl, categories, label, className, onClose }: IMenuCategorizedPopupProps = props;

  const menuActionsRef = useRef<PopoverActions | null>(null);

  const updateMenuPosition = () => {
    menuActionsRef.current?.updatePosition();
  };

  const categoryAccordionProps = useMemo<Partial<AccordionProps>>(
    () => ({
      onAnimationEnd: () => {
        menuActionsRef.current?.updatePosition();
      },
      TransitionProps: {
        onEntered: updateMenuPosition,
        onExited: updateMenuPosition,
      },
    }),
    []
  );

  return (
    <MenuCategorized
      className={className}
      action={menuActionsRef}
      $label={label}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <MenuCategorizedContent categories={categories} categoryAccordionProps={categoryAccordionProps} />
    </MenuCategorized>
  );
};
