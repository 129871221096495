import { SpaceCreated, SpaceUpdated, SpaceDeleted } from '@playq/octopus-notifications';

import { CodeWrapper } from './styles';

export interface ISpaceNotificationViewProps {
  notification: SpaceCreated | SpaceUpdated | SpaceDeleted;
  inline?: boolean;
}

export const SpaceNotificationView = (props: ISpaceNotificationViewProps) => {
  const { notification, inline } = props;

  if (inline) {
    return <span>{notification.message}</span>;
  }
  return <CodeWrapper>{JSON.stringify(notification.serialize(), undefined, '  ')}</CodeWrapper>;
};
