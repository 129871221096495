import { useState, useRef, useEffect, cloneElement, SyntheticEvent } from 'react';
import MatButton from '@mui/material/Button';
import Popover from '@mui/material/Popover';

import { FCWithChildren } from '/common/models';

import { IPopoverButtonProps } from './types';

export const PopoverButton: FCWithChildren<IPopoverButtonProps> = ({
  open: propsOpen,
  button,
  buttonProps = {},
  popoverProps = {
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  },
  canClose = true,
  onClose,
  children,
}) => {
  const anchorEl = useRef(null);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  useEffect(() => {
    if (propsOpen !== undefined) {
      setPopoverOpen(propsOpen);
    }
  }, [propsOpen]);

  const handleClick = () => {
    setPopoverOpen(true);
  };

  const handleClose = (e: SyntheticEvent, reason: string) => {
    e.stopPropagation();
    if (canClose) {
      setPopoverOpen(false);
      if (onClose) {
        onClose(reason);
      }
    }
  };

  const renderChildren = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return typeof children === 'function' ? children({ handleClose }) : children;
  };

  return (
    <>
      {button ? (
        cloneElement(button, {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
          onClick: propsOpen === undefined ? handleClick : button.props.onClick,
          ref: anchorEl,
          'aria-haspopup': true,
        })
      ) : (
        <MatButton
          onClick={handleClick}
          ref={anchorEl}
          aria-owns={popoverOpen ? 'simple-popper' : undefined}
          aria-haspopup='true'
          {...buttonProps}
        />
      )}

      <Popover open={popoverOpen} anchorEl={anchorEl.current} onClose={handleClose} {...popoverProps}>
        {renderChildren()}
      </Popover>
    </>
  );
};
