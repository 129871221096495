import {
  DeviceUnknown as UnknownDeviceIcon,
  SecurityUpdateGood as KnownDeviceIcon,
  Save as SaveIcon,
  Edit as EditIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Notifications as NotificationsIcon,
  NotificationsNone as NotificationsNoneIcon,
} from '@mui/icons-material';

import { IDeviceIcon } from '../types';

export const typeIconAndTooltip: Record<IDeviceIcon, { icon: JSX.Element; tooltip: string }> = {
  [IDeviceIcon.Edit]: {
    icon: <EditIcon />,
    tooltip: 'Edit',
  },
  [IDeviceIcon.Save]: {
    icon: <SaveIcon />,
    tooltip: 'Bind',
  },
  [IDeviceIcon.Remove]: {
    icon: <DeleteIcon />,
    tooltip: 'Unbind',
  },
  [IDeviceIcon.Check]: {
    icon: <CheckIcon />,
    tooltip: 'Update',
  },
  [IDeviceIcon.Cancel]: {
    icon: <CloseIcon />,
    tooltip: 'Cancel',
  },
  [IDeviceIcon.Unknown]: {
    icon: <UnknownDeviceIcon color='warning' />,
    tooltip: 'Unknown',
  },
  [IDeviceIcon.Known]: {
    icon: <KnownDeviceIcon color='success' />,
    tooltip: 'Known',
  },
  [IDeviceIcon.Subscribe]: {
    icon: <NotificationsIcon />,
    tooltip: 'Subscribe',
  },
  [IDeviceIcon.Unsubscribe]: {
    icon: <NotificationsNoneIcon />,
    tooltip: 'Unsubscribe',
  },
};
