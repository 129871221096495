import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogTitle, Fade, Stack, Tooltip, Typography } from '@mui/material';
import { Group as GroupIcon } from '@mui/icons-material';

import { currentModuleToolkit } from '/store';

import { BadgeStyled, ButtonStyled, DialogContentStyled, OnlineIndicator } from './styles';

export const OnlinePageUsers: FC = () => {
  const users = useSelector(currentModuleToolkit.selectors.selectModuleUsers);

  const [open, setOpen] = useState(false);
  const [usersLength, setUsersLength] = useState<number>(0);

  useEffect(() => {
    if (users.length !== 0) {
      setUsersLength(users.length);
    }
  }, [users.length]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const noUsers = users.length === 0;
  const allUsersAway = users.every((user) => user.away);

  return (
    <>
      <Fade in={!noUsers}>
        <Tooltip title='View all users' placement='bottom'>
          <ButtonStyled onClick={handleOpen} color='inherit'>
            <BadgeStyled
              $away={allUsersAway}
              invisible={noUsers}
              variant='dot'
              anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
              }}
            >
              <GroupIcon />
            </BadgeStyled>
            {usersLength}
          </ButtonStyled>
        </Tooltip>
      </Fade>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>On this page</DialogTitle>
        <DialogContentStyled>
          <Stack spacing={1}>
            {users.map((user) => (
              <Stack direction='row' key={user.userID} spacing={2} sx={{ alignItems: 'center' }}>
                <Tooltip title={user.away ? 'Away' : 'Online'} placement='top'>
                  <OnlineIndicator away={user.away} />
                </Tooltip>
                <Typography>{user.userName}</Typography>
              </Stack>
            ))}
            {users.length === 0 && <Typography color='textSecondary'>No users found</Typography>}
          </Stack>
        </DialogContentStyled>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
