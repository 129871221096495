import { LoadingText } from './styles';

export const LoadingTextProgress = () => {
  return (
    <LoadingText>
      Connecting
      <span className='dot'>.</span>
      <span className='dot'>.</span>
      <span className='dot'>.</span>
    </LoadingText>
  );
};
