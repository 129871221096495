import { Accordion, AccordionDetails, AccordionSummary, MenuItem, styled } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

import { IWithCSS } from '/common/models';

export const Category = styled(Accordion)<IWithCSS>`
  border-bottom: 1px solid ${({ theme }) => theme.palette.action.focus};

  &:last-child {
    border-bottom: none;
  }

  &.Mui-expanded,
  .Mui-expanded {
    margin: 0;
  }

  &:before {
    content: unset;
  }

  ${({ css }) => css}
`;

export const CategoryLabel = styled(AccordionSummary)<IWithCSS>`
  position: relative;
  min-height: 0;
  padding-top: 12px;
  padding-bottom: 12px;

  &.Mui-expanded {
    min-height: 0;

    .MuiSvgIcon-root {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }

  ${({ css }) => css}
`;

export const CategoryItemsList = styled(AccordionDetails)<IWithCSS>`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: -6px;

  ${({ css }) => css}
`;

export const CategoryLabelText = styled('span')<IWithCSS>`
  font-size: 0.9rem;
  margin-right: 1.8rem;

  ${({ css }) => css}
`;

export const CategoryLabelIcon = styled(KeyboardArrowDown)<IWithCSS>`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.15s ease-out;
  color: ${({ theme }) => theme.palette.text.secondary};

  ${({ css }) => css}
`;

export const CategoryItem = styled(MenuItem)`
  font-size: 0.9rem;
  padding-left: 25px;
`;
