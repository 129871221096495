import { styled } from '@mui/material';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${(props) => props.theme.spacing(1)};
  margin-bottom: ${(props) => props.theme.spacing(1)};

  .env-form:first-of-type {
    margin-right: ${(props) => props.theme.spacing(3)};
    margin-left: ${(props) => props.theme.spacing(1)};
  }
`;

export const PartialFade = styled('div')`
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.active {
    pointer-events: none;
    opacity: 0.5;
  }
`;
