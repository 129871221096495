import { AppID, Environment } from '@playq/octopus-common';
import { App, Space } from '@playq/octopus2-apps';

import { buckets } from '/helpers/aws';

import { EntityID } from '../components/Service/types';

export const createManifestLink = (
  env: Environment,
  version: number,
  entityID: EntityID,
  entityLink?: string,
  app?: App,
  space?: Space
): string | null => {
  if (!app || !entityLink) {
    return null;
  }

  const id = 'id' in entityID ? (entityID as unknown as { id: number }).id : entityID;

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const promotedEntityVersion = entityID instanceof AppID ? version : `${id}/${version}`;

  let link = `${buckets[env]}/${app.fingerprintID.app}/`;
  if (space && env === Environment.Sandbox) {
    link += `spaces/${space.id.id}/`;
  }

  link += `${entityLink}/${promotedEntityVersion}/manifest.json`;
  return link;
};
