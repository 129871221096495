import { FC, MouseEvent as ReactMouseEvent, useCallback, useRef } from 'react';

import { ResizableProps } from './types';
import { Dragger } from './styles';

export const Resizable: FC<ResizableProps> = ({ componentRef, onChange }) => {
  const active = useRef(false);
  const prevWidth = useRef(0);
  const prevClientX = useRef(0);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (active.current === false || componentRef.current === null) {
        return;
      }
      const newWidth = prevWidth.current + (prevClientX.current - e.clientX);
      if (onChange !== undefined) {
        onChange(componentRef.current, newWidth);
      } else {
        componentRef.current.style.width = `${newWidth}px`;
      }
    },
    [componentRef, onChange]
  );

  const handleMouseUp = useCallback(() => {
    active.current = false;
    document.body.style.removeProperty('cursor');
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove]);

  const handleMouseDown = useCallback(
    (e: ReactMouseEvent) => {
      e.preventDefault();
      active.current = true;
      if (componentRef.current) {
        prevWidth.current = componentRef.current.clientWidth;
        prevClientX.current = e.clientX;
      }
      document.body.style.cursor = 'col-resize';

      document.addEventListener('mousemove', handleMouseMove, false);
      document.addEventListener('mouseup', handleMouseUp, false);
    },
    [componentRef, handleMouseMove, handleMouseUp]
  );

  return <Dragger onMouseDown={handleMouseDown} />;
};
