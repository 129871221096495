import { useDispatch } from 'react-redux';

import { DevicesSort, DevicesSortField } from '@playq/octopus2-devices';
import { OffsetLimit } from '@playq/services-shared';
import { SortDirection } from '@playq/octopus-common';

import { useDeleteDevice, useGetDevices, useUpdateDevice } from '/api';
import { deviceToolkit } from '/store';
import { usePersistedQuery } from '/hooks';
import { PersistedQueryKeys } from '/common/models';
import { ICollectionTableQueryChange } from '/shared/Table';
import { snackbarService } from '/common/snackbarService';

import { DeviceQuery } from './types';

export const useDevice = () => {
  const [query, setQuery] = usePersistedQuery(PersistedQueryKeys.Devices, DevicesSort, {
    filterBy: {},
    sortBy: [
      new DevicesSort({
        field: DevicesSortField.Connected,
        ord: SortDirection.Descending,
      }),
      new DevicesSort({
        field: DevicesSortField.Name,
        ord: SortDirection.Ascending,
      }),
    ],
    iterator: new OffsetLimit({ offset: 0, limit: 10 }),
  });
  const dispatch = useDispatch();
  const { filterBy, sortBy, iterator } = query;

  const devices = useGetDevices(101, iterator, sortBy, filterBy);
  const { mutate: update } = useUpdateDevice({
    onSuccess: (_, variable) => {
      dispatch(
        deviceToolkit.actions.updateSubscriptionName({ id: variable.device.serialize(), name: variable.data.name })
      );
      snackbarService.success('Device has been updated');
    },
    onMutate: ({ device, data: { name } }) => {
      devices.mutateUpdateDeviceCache(device, name);
    },
    onError: (error) => {
      snackbarService.error(error.message);
    },
  });
  const { mutate: remove } = useDeleteDevice({
    onSuccess: () => {
      snackbarService.success('Device has been unbound');
    },
    onMutate: ({ device }) => {
      devices.mutateRemoveDeviceCache(device);
    },
    onError: (error) => {
      snackbarService.error(error.message);
    },
  });

  const handleQueryChange = (qc: ICollectionTableQueryChange<DeviceQuery>) => {
    setQuery(qc.query);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return {
    devices,
    handleQueryChange,
    query,
    update,
    remove,
  };
};
