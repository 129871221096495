import { styled } from '@mui/material';
import { keyframes } from '@mui/system';

const barberPole = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 60px 30px;
  }
`;

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3px 10px;
  box-shadow:
    0 0 8px 0 rgba(0, 0, 0, 0.06),
    0 1px 0 0 rgba(0, 0, 0, 0.02);
  text-align: center;
  background-size: 30px 30px;

  &.loading {
    background-image: linear-gradient(
      45deg,
      rgba(82, 151, 255, 0.1) 25%,
      transparent 25%,
      transparent 50%,
      rgba(82, 151, 255, 0.1) 50%,
      rgba(82, 151, 255, 0.1) 75%,
      transparent 75%,
      transparent
    );
    animation: ${barberPole} 0.5s linear infinite;
  }
`;

export const Title = styled('div')`
  font-size: 12px;
`;

export const Text = styled('div')`
  color: var(--main-color, black);
  font-size: 13px;
  font-weight: 500;
`;
