import { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Save as SaveIcon,
  Delete as DeleteIcon,
  CheckCircle as SubscribeIcon,
  PersonSearch as PersonSearchIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { Box, Stack, Tooltip, Typography } from '@mui/material';

import {
  DeviceID,
  AppDevice,
  DevicesSort,
  DevicesFilterField,
  DevicesSortField,
  DevicesSortFieldHelpers,
  DevicesFilterFieldHelpers,
  DeviceIdentityDataStruct,
} from '@playq/octopus2-devices';

import { CollectionTable, ICollectionTableColumn, ITableRowAction } from '/shared/Table';
import { DeviceTableProps, IDeviceIcon, typeIconAndTooltip } from '/shared/OnlineDevices';
import { deviceToolkit } from '/store';
import { formatDate } from '/helpers';
import { useIdentifyDevice } from '/api';
import { snackbarService } from '/common/snackbarService';

import { OnlineIndicator } from '../../component/Device/styles';
import { checkDeviceName, checkDeviceStatus, checkDeviceConnection } from '../../component/Device/helpers';

import { TypographyStyled } from './styles';
import { FieldName } from './components';
import { useDevice } from './useDevice';

const Table = CollectionTable.ofType<AppDevice, DevicesSortField, DevicesFilterField, DevicesSort>();

export const DeviceTable: FC<DeviceTableProps> = ({ changeSubscription, subscribeDevice, unsubscribe }) => {
  //TODO: optimise fieldName, to seperate components
  const { devices, query, update, remove, handleQueryChange } = useDevice();
  const [editDevice, setEditDevice] = useState<Record<string, string>>({ id: '', name: '' });
  const changeDeviceInfo = (info: { name: string; id?: string }) => {
    return setEditDevice((prev) => ({
      ...prev,
      ...info,
    }));
  };

  const subscribtionDevice = useSelector(deviceToolkit.selectors.subscribtionDevice);
  const cleanDeviceInfo = useCallback(() => setEditDevice({}), []);

  const onSave = useCallback(
    (deviceId: DeviceID, name: string) => {
      if (!name) return;
      const currentDeviceName = new DeviceIdentityDataStruct({ name });
      update({ device: deviceId, data: currentDeviceName });
      cleanDeviceInfo();
    },
    [cleanDeviceInfo, update]
  );

  const { mutate: identify } = useIdentifyDevice({
    onSuccess: (data, variables) => {
      snackbarService.success(`${data}:${variables.data}`);
    },
  });
  const editModeOn = (deviceID: DeviceID): boolean => {
    return deviceID.serialize() === editDevice.id;
  };
  const saveOrEditField = (device: AppDevice) => {
    const currentName = checkDeviceName(device.device);
    changeDeviceInfo({ id: device.device.id.serialize(), name: currentName });
  };
  const columns: ICollectionTableColumn<AppDevice, DevicesSortField, DevicesFilterField>[] = [
    {
      label: 'ID',
      queryKey: DevicesFilterField.ID,
      render: (device) => device.device.id.device,
    },
    {
      label: 'Status',
      queryKey: DevicesFilterField.Connected,
      sort: DevicesSortField.AppName,

      render: (device) => {
        const knownDevice = checkDeviceStatus(device.device);
        const title = typeIconAndTooltip[knownDevice ? IDeviceIcon.Known : IDeviceIcon.Unknown].tooltip;
        const icon = typeIconAndTooltip[knownDevice ? IDeviceIcon.Known : IDeviceIcon.Unknown].icon;

        return (
          <Box position='relative'>
            <Tooltip title={title} placement='top'>
              {icon}
            </Tooltip>

            <OnlineIndicator online={device.device.connection !== undefined} />
          </Box>
        );
      },
    },
    {
      label: 'Name',
      queryKey: DevicesFilterField.Name,
      render: (device) => {
        const currentName = checkDeviceName(device.device);
        const subscribed = subscribtionDevice?.device.id.serialize() === device.device.id.serialize();

        return (
          <Stack spacing={1} direction='row' alignItems='center' sx={{ cursor: 'pointer' }}>
            {editModeOn(device.device.id) ? (
              <FieldName
                value={editDevice.name}
                onCancel={cleanDeviceInfo}
                onSave={() => onSave(device.device.id, editDevice.name)}
                onChange={(e) => changeDeviceInfo({ name: e.target.value })}
              />
            ) : (
              <TypographyStyled
                onClick={() => {
                  if (subscribtionDevice?.device !== undefined) {
                    changeSubscription(subscribtionDevice?.device.id.serialize(), device.device);
                  } else {
                    subscribeDevice(device.device.id?.serialize(), device.device);
                  }
                }}
              >
                {currentName}
              </TypographyStyled>
            )}

            {subscribed && !editModeOn(device.device.id) && <SubscribeIcon sx={{ color: '#008000' }} />}
          </Stack>
        );
      },
    },
    {
      label: 'Platform',
      render: (device) => device.device.alias.os,
    },
    {
      label: 'Last Active',
      queryKey: DevicesFilterField.LastActive,
      render: (device) => formatDate(device.device.lastActive, 'DD t'),
    },
    {
      label: 'App',
      render: (device) => {
        if (device.device.connection !== undefined) {
          return <Typography component='span'>{device.appName}</Typography>;
        }
      },
    },
  ];
  const actions: ITableRowAction<AppDevice>[] = [
    {
      label: 'Bind',
      icon: SaveIcon,
      tooltip: () => typeIconAndTooltip[IDeviceIcon.Save].tooltip,
      onClick: (_, device) => saveOrEditField(device),
      hidden: (device) => {
        const isHidden = editModeOn(device.device.id) || checkDeviceStatus(device.device);
        return isHidden;
      },
    },
    {
      label: 'Edit',
      icon: EditIcon,
      tooltip: () => typeIconAndTooltip[IDeviceIcon.Edit].tooltip,
      onClick: (_, device) => saveOrEditField(device),
      hidden: (device) => {
        const isHidden = editModeOn(device.device.id) || !checkDeviceStatus(device.device);
        return isHidden;
      },
    },
    {
      label: 'Unbind',
      icon: DeleteIcon,
      tooltip: typeIconAndTooltip[IDeviceIcon.Remove].tooltip,
      onClick: (_, device) => {
        remove({ device: device.device.id });
        if (subscribtionDevice?.device.id.serialize() === device.device.id.serialize()) {
          unsubscribe(false);
        }
      },
      hidden: (device) => {
        const isHidden = !checkDeviceStatus(device.device) || editModeOn(device.device.id);
        return isHidden;
      },
    },
    {
      label: 'Identify',
      icon: PersonSearchIcon,
      tooltip: 'Identify',
      onClick: (_, device) => identify({ device: device.device.id, data: device.device.id.device.toString() }),
      hidden: (device) => {
        const isHidden = !checkDeviceConnection(device.device) || editModeOn(device.device.id);
        return isHidden;
      },
    },
  ];

  return (
    <Table
      sortHelper={DevicesSortFieldHelpers}
      filterHelper={DevicesFilterFieldHelpers}
      sortClass={DevicesSort}
      initialQuery={query}
      pending={true}
      onQueryChange={handleQueryChange}
      data={devices.devices}
      error={devices.error?.message}
      processing={devices.isLoading}
      columns={columns}
      placeholder={{ text: 'There are no devices' }}
      size='medium'
      total={devices.total}
      actions={actions}
    />
  );
};
